<template>
  <ik-data-table
    :entity-name="$t('device_list')"
    :icon="'mdi-devices'"
    :model="model"
  >
    <template #footer>
      <v-dialog max-width="400px">
        <v-card class="pa-4">
          <div class="text-h3 font-weight-bold">
            {{ $t('labelSizeSetting') }}
          </div>
          <v-card
            outlined
            class="mt-2 pa-2"
          >
            <div class="text-subtitle-1">
              {{ $t('description') }}
            </div>
            <div class="text--secondary text-body-2">
              {{ $t('label_paper_description_1') }}<br>
              {{ $t('label_paper_description_2') }}
            </div>
          </v-card>

          <div class="mt-2">
            <div class="d-flex">
              <div>
                <div class="text-overline">
                  {{ $t('paperLong') }}
                </div>
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :placeholder="$t('unitMM')"
                >
                  <template #append>
                    <div style="margin-top: 2px">
                      mm
                    </div>
                  </template>
                </v-text-field>
              </div>
              <div style="width: 16px"/>
              <div>
                <div class="text-overline">
                  {{ $t('paperHigh') }}
                </div>
                <v-text-field
                  dense
                  hide-details
                  outlined
                  :placeholder="$t('unitMM')"
                >
                  <template #append>
                    <div style="margin-top: 2px">
                      mm
                    </div>
                  </template>
                </v-text-field>
              </div>
            </div>

            <div>
              <div class="text-overline">
                paperSpacing
              </div>
              <v-text-field
                dense
                hide-details
                outlined
                :placeholder="$t('unitMM')"
              >
                <template #append>
                  <div style="margin-top: 2px">
                    mm
                  </div>
                </template>
              </v-text-field>
            </div>
            <div>
              <v-checkbox
                dense
                hide-details
                :label="$t('determineThePaper')"
              />
            </div>
            <div class="mt-2">
              <v-btn
                elevation="0"
                block
                disabled
                color="primary"
              >
                <v-icon left>
                  mdi-save
                </v-icon>
                {{ $t('save') }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <template #activator="{on}">
          <v-btn
            outlined
            elevation="0"
            class="ml-2"
            color="success darken-2"
            v-on="on"
          >
            <v-icon left>
              mdi-file-find
            </v-icon>
            labelSizeSetting
          </v-btn>
        </template>
      </v-dialog>
    </template>
  </ik-data-table>
</template>

<script>

  import DeviceList from '../../../model/print/DeviceList'
  import { IkDataTable } from 'metaflow-js'

  export default {
    components: { IkDataTable },
    data: function () {
      return ({
        model: DeviceList,
      })
    },
  }

</script>
