import GlobalSettings from '@/GlobalSettings'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import store from '@/store'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    header: false,
    form: false,
  },
  name: {
    displayName: 'sectionName',
  },
  image: {
    type: IKDataEntity.Types.Image,
    formConfig: {
      type: {
        root: () => store.getters.rootUrl,
      },
      required: false,
    },
    displayName: 'sectionImg',
  },
  tableCount: {
    form: false,
  },
}
const config = {
  add: function (item) {
    return hillo.postWithUploadFile('Section.php?op=add', item)
  },
  edit: function (item) {
    item.servantIds = JSON.stringify([])
    return hillo.postWithUploadFile('Section.php?op=update', item, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('Section.php?op=delete', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Section.php', {
      op: 'view',
      lang: GlobalSettings.getLang(),
      ...filter,
    }))
      .content.filter(it => it.id !== '6')
  },
}

export default IKDataEntity.ModelFactory(entity, config)
